<template>
  <div class="_content">
    <div class="header-pas">
      <a-row>
        <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
          <div class="logo-pas">
            <img src="../../assets/img/logo.png" />
          </div>

        </a-col>
       
        <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
          <div class="under-logo-pas">
            <h2><span style="font-weight: 600;">Providing <br />Solution</span> <br /><span
                style="font-family:'Carattere'">for</span> Better <br />Business</h2>
              <img src="../../assets/img/underhead.png" />
          </div>
          <div class="textheader-pas">
            
            
            <p>
              We are grateful to everyone for supporting our business. This
              event was curated to celebrate our journey with you. We hope you
              enjoy experiencing our new solutions and win exciting prizes!
            </p>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="registpas">
    <a-row>
      <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
        <div >
          <h2>RSVP</h2>
          <span>Please Confirm Your Attendance</span>
    
          <div style="margin-top: 50px">
            <div class="successpage" v-if="successpage">
              <a-icon type="check-circle" theme="filled" />
              <h3>Sukses !</h3>
              <p>Silahkan periksa email anda. Terimakasih</p>
            </div>
            <a-form :form="form" @submit="handleSubmit" class="formregis" v-else>
              <a-row :gutter="15">
                <a-col :lg="{ span: 12 }">
                  <a-form-item>
                    <a-input autocomplete="off" v-decorator="[
                              'fullName',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]" type="text" placeholder="Full Name">
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :lg="{ span: 12 }">
                  <a-form-item>
                    <a-input autocomplete="off" v-decorator="[
                              'company',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]" type="text" placeholder="Company">
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :lg="{ span: 12 }">
                  <a-form-item>
                    <a-input autocomplete="off" v-decorator="[
                              'email',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]" type="email" placeholder="Email Address">
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :lg="{ span: 12 }">
                  <a-form-item>
                    <a-input autocomplete="off" v-decorator="[
                              'phone',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: 'required!',
                                  },
                                ],
                              },
                            ]" type="text" placeholder="Phone Number">
                    </a-input>
                  </a-form-item>
    
    
    
    
                </a-col>
                <a-col :lg="{ span: 24 }"><a-button type="link" html-type="submit" class="login-form-button"
                    :loading="loading">
                   Confirm
                  </a-button></a-col>
              </a-row>
            </a-form>
            <div style="margin-top:20px">
              <small>*
                <i>This invitation is valid only for the intended recipient.</i>
              </small>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
   </div>

    <div class="detailinfo-pas">
      <a-row>
        <a-col :xs="{ span: 20, offset: 2 }" :lg="{ span: 14, offset: 5 }">
          <a-row>
            <a-col :lg="{ span: 8 }">
              <div class="alamat-pas">
                <h2>DETAIL INFORMATION</h2>
                <h3>Wednesday,</h3> 
                <h3>22 February 2023</h3> 
                <p>10.00 – 15.30 WIB</p>
                <h3>THE TRIBRATA</h3>
               
                 <h3>Opus Ballroom 3 & 4 </h3><p>Jl. Darmawangsa III No.2,
                  <br />Kebayoran Baru, Jakarta Selatan
                </p>
                <a-button type="link" @click="openMap('https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.282412523879!2d106.7985076!3d-6.2544288!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15973de4b1c09abd!2sThe%20Tribrata%20Darmawangsa%20Jakarta!5e0!3m2!1sen!2sid!4v1674580153154!5m2!1sen!2sid')" class="login-form-button maps-buttom">
                  Open In Maps
                </a-button>
              </div>
             
            </a-col>

            <!-- <a-col :lg="{ span: 16 }">
              <div style="margin: 0 20px 20px">
                <iframe
                  width="100%"
                  height="450"
                  style="border: 0"
                  loading="lazy"
                  allowfullscreen
                  referrerpolicy="no-referrer-when-downgrade"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.282412523879!2d106.7985076!3d-6.2544288!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15973de4b1c09abd!2sThe%20Tribrata%20Darmawangsa%20Jakarta!5e0!3m2!1sen!2sid!4v1674580153154!5m2!1sen!2sid"
                >
                </iframe>
              </div>
            </a-col> -->
          </a-row>
        </a-col>
      </a-row>
    </div>

    
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "register" });
  },

  data() {
    return {
      loading: false,
      successpage: false,
      showregister: false,
      register: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    ...mapState(["token"]),
    ...mapGetters(["isAuth"]),
  },

  created() {
    if (this.isAuth == true) {
      this.showregister = true;
    }
  },
  methods: {
    openMap(e) {
      window.open(e)
    },
    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          //console.log(values);
          this.loading = true;
          this.$store
            .dispatch("RegisterUser", {
              ...values,
            })
            .then((response) => {
              this.loading = true;
              this.successpage = true;
              this.form.resetField();
              //console.log(response);
            })
            .catch((error) => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
