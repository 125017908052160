var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"_content"},[_c('div',{staticClass:"header-pas"},[_c('a-row',[_c('a-col',{attrs:{"xs":{ span: 20, offset: 2 },"lg":{ span: 16, offset: 4 }}},[_c('div',{staticClass:"logo-pas"},[_c('img',{attrs:{"src":require("../../assets/img/logo.png")}})])]),_c('a-col',{attrs:{"xs":{ span: 20, offset: 2 },"lg":{ span: 14, offset: 5 }}},[_c('div',{staticClass:"under-logo-pas"},[_c('h2',[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Providing "),_c('br'),_vm._v("Solution")]),_vm._v(" "),_c('br'),_c('span',{staticStyle:{"font-family":"'Carattere'"}},[_vm._v("for")]),_vm._v(" Better "),_c('br'),_vm._v("Business")]),_c('img',{attrs:{"src":require("../../assets/img/underhead.png")}})]),_c('div',{staticClass:"textheader-pas"},[_c('p',[_vm._v(" We are grateful to everyone for supporting our business. This event was curated to celebrate our journey with you. We hope you enjoy experiencing our new solutions and win exciting prizes! ")])])])],1)],1),_c('div',{staticClass:"registpas"},[_c('a-row',[_c('a-col',{attrs:{"xs":{ span: 20, offset: 2 },"lg":{ span: 14, offset: 5 }}},[_c('div',[_c('h2',[_vm._v("RSVP")]),_c('span',[_vm._v("Please Confirm Your Attendance")]),_c('div',{staticStyle:{"margin-top":"50px"}},[(_vm.successpage)?_c('div',{staticClass:"successpage"},[_c('a-icon',{attrs:{"type":"check-circle","theme":"filled"}}),_c('h3',[_vm._v("Sukses !")]),_c('p',[_vm._v("Silahkan periksa email anda. Terimakasih")])],1):_c('a-form',{staticClass:"formregis",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":15}},[_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'fullName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'fullName',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Full Name"}})],1)],1),_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'company',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'company',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Company"}})],1)],1),_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'email',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'email',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"email","placeholder":"Email Address"}})],1)],1),_c('a-col',{attrs:{"lg":{ span: 12 }}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'phone',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: 'required!',
                                },
                              ],
                            },
                          ]),expression:"[\n                            'phone',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: 'required!',\n                                },\n                              ],\n                            },\n                          ]"}],attrs:{"autocomplete":"off","type":"text","placeholder":"Phone Number"}})],1)],1),_c('a-col',{attrs:{"lg":{ span: 24 }}},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"link","html-type":"submit","loading":_vm.loading}},[_vm._v(" Confirm ")])],1)],1)],1),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('small',[_vm._v("* "),_c('i',[_vm._v("This invitation is valid only for the intended recipient.")])])])],1)])])],1)],1),_c('div',{staticClass:"detailinfo-pas"},[_c('a-row',[_c('a-col',{attrs:{"xs":{ span: 20, offset: 2 },"lg":{ span: 14, offset: 5 }}},[_c('a-row',[_c('a-col',{attrs:{"lg":{ span: 8 }}},[_c('div',{staticClass:"alamat-pas"},[_c('h2',[_vm._v("DETAIL INFORMATION")]),_c('h3',[_vm._v("Wednesday,")]),_c('h3',[_vm._v("22 February 2023")]),_c('p',[_vm._v("10.00 – 15.30 WIB")]),_c('h3',[_vm._v("THE TRIBRATA")]),_c('h3',[_vm._v("Opus Ballroom 3 & 4 ")]),_c('p',[_vm._v("Jl. Darmawangsa III No.2, "),_c('br'),_vm._v("Kebayoran Baru, Jakarta Selatan ")]),_c('a-button',{staticClass:"login-form-button maps-buttom",attrs:{"type":"link"},on:{"click":function($event){return _vm.openMap('https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15864.282412523879!2d106.7985076!3d-6.2544288!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x15973de4b1c09abd!2sThe%20Tribrata%20Darmawangsa%20Jakarta!5e0!3m2!1sen!2sid!4v1674580153154!5m2!1sen!2sid')}}},[_vm._v(" Open In Maps ")])],1)])],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }